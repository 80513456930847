import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import get from 'lodash/get'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageData: get(this.props.data, 'contentfulContactUs'),
    }
  }

  showCalendy = e => {
    Calendly.initPopupWidget({
      url:
        'https://calendly.com/limitlesswithjess/initial-90-min-consultation?text_color=4a4a4a&primary_color=ff0021',
    })
  }

  render() {
    const { location } = this.props
    const { pageData } = this.state

    const siteMetadata = {
      title: 'Contact Us | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/contact',
    }
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
          <div className="main-wrapper clearfix">
            <div className="widget-list">
              <div id="contact-page__bg-widget" className="widget-holder">
                <div
                  className="widget-bg pb-150 pt-200"
                  style={{
                    background: `url(${pageData.bannerImage.file.url})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div className="widget-body container">
                    <div className="row">
                      <div className="col-12 text-center contact-banner-text">
                        <p className="mb-0">
                          {pageData.heading1 ? pageData.heading1 : ''}
                        </p>
                        <h1 className="faq-bnr-txt fw-900">
                          {pageData.heading2 ? pageData.heading2 : ''}
                        </h1>
                        <Scrollspy
                          items={['contact-page__title-widget']}
                          className="slider-quote-btn scrollContentData pl-0"
                        >
                          <Scroll
                            type="id"
                            element="contact-page__title-widget"
                          >
                            <button
                              className="btn btn-sm btn-circle btn-secondary scroll-to-element"
                              id="contact-page__title-scroll-btn"
                            >
                              <i className="feather-chevron-down"></i>
                            </button>
                          </Scroll>
                        </Scrollspy>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="contact-page__title-widget" className="widget-holder">
                <div className="widget-bg">
                  <div className="widget-body container">
                    <div className="row">
                      <div className="col-md-5">
                        <h4 className="fw-600" id="contact-page__title">
                          {pageData.leftSectionHeading1
                            ? pageData.leftSectionHeading1
                            : ''}
                        </h4>
                        <p>
                          {pageData.leftSectionDescription
                            ? pageData.leftSectionDescription
                                .leftSectionDescription
                            : ''}
                        </p>
                        <div className="widget text-widget section-sub-heading-lines text-left d-block mt-0 contact-details">
                          <p className="d-flex flex-column align-items-start fs-14">
                            P:{' '}
                            {pageData.leftSectionPhone
                              ? pageData.leftSectionPhone
                              : ''}
                            {/* <br /> */}
                            {/* A:{' '}
                            {pageData.leftSectionAddress
                              ? pageData.leftSectionAddress
                              : ''} */}
                            <br />
                            <a href="">
                              E:{' '}
                              <span className="text-lowercase">{pageData.leftSectionEmail
                                ? pageData.leftSectionEmail
                                : ''}</span>
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-7 ml-auto">
                        <div className="row">
                          <div
                            className="col-md-10 mx-auto pos-relative"
                            id="contact-page__form"
                          >
                            <h3 className="fw-500">
                              Feel free to contact with us by using the form
                              below
                            </h3>
                            <form
                              name="contact"
                              method="POST"
                              autoComplete="on"
                              data-netlify="true"
                              data-netlify-honeypot="bot-field"
                              action="/thank-you"
                            >
                              {' '}
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      className="form-control"
                                      required
                                    />

                                    <input type="hidden" name="bot-field" />
                                    <input
                                      type="hidden"
                                      name="form-name"
                                      value="contact"
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6">
                                  <div className="input-group">
                                    <input
                                      type="email"
                                      name="email"
                                      placeholder="Email"
                                      className="form-control"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="subject"
                                    placeholder="Subject"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="input-group">
                                  <textarea
                                    placeholder="Message"
                                    name="message"
                                    className="form-control"
                                    rows="5"
                                    required
                                  ></textarea>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-fancy btn-block fs-14 py-3"
                              >
                                Send Message{' '}
                                <i className="feather-arrow-right"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="widget-holder btn-schdule">
                <div className="widget-bg">
                  <div className="widget-body container-fluid">
                    <div className="row ">
                      <div className="col">
                        <div href="#" className="">
                          <h2 className="text-white color-white">
                            {pageData.appointmentSectionHeading1
                              ? pageData.appointmentSectionHeading1
                              : ''}
                          </h2>
                          <button
                            onClick={e => this.showCalendy(e)}
                            className="btn btn-white bg-white"
                          >
                            {pageData.appointmentSectionButtonText
                              ? pageData.appointmentSectionButtonText
                              : ''}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  {
    contentfulContactUs {
      heading1
      heading2
      bannerImage {
        file {
          url
        }
        description
      }
      leftSectionHeading1
      leftSectionDescription {
        leftSectionDescription
      }
      leftSectionPhone
      leftSectionAddress
      leftSectionEmail
      appointmentSectionHeading1
      appointmentSectionButtonText
    }
  }
`
